import { matchPath, useLocation } from "react-router-dom";
import SidebarDesktopMypage from "./SidebarDesktopMypage";
import { pageRoute } from "../../../constants/pageRoute.constant";
import SidebarDesktopMyProjects from "./SidebarDesktopMyProjects";

function SidebarDesktop() {
  const location = useLocation();

  const isMypage = !!matchPath(pageRoute.MYPAGE + "/*", location.pathname);
  const isMyProjects = !!matchPath(
    pageRoute.MY_PROJECT + "/*",
    location.pathname
  );

  return isMypage ? (
    <SidebarDesktopMypage />
  ) : isMyProjects ? (
    <SidebarDesktopMyProjects />
  ) : null;
}

export default SidebarDesktop;
