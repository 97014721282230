import { Modal } from "antd";
import { useRecoilState } from "recoil";
import { isHandleBackModalOpenState } from "../../atoms/modalState";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

function Modals() {
  const { isMobile } = useWindowSize();
  const [isHandleBackModalOpen, setIsHandleBackModalOpen] = useRecoilState(
    isHandleBackModalOpenState
  );
  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={isHandleBackModalOpen}
        onOk={() => {
          setIsHandleBackModalOpen(false);
          navigate(-1);
        }}
        okText="네"
        onCancel={() => setIsHandleBackModalOpen(false)}
        cancelText="취소"
        width={400}
        centered={isMobile ? true : false}
      >
        <ModalContent>이전 페이지로 이동하시겠습니까?</ModalContent>
      </Modal>
    </>
  );
}

export default Modals;
