import styled from "styled-components";
import socialKakao from "../../../assets/icons/social-kakao.png";
import socialGoogle from "../../../assets/icons/social-google.png";
import { localStorageKey } from "../../../constants/localStorageKey.constant";
import { Provider } from "../../../types";
import { setLocalStorage } from "../../../helpers/storage.helper";
import { ENV } from "../../../env";
import { commonConstants } from "../../../constants/common.constant";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 560px;
  width: 635px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: ${(props) => props.theme.backgroundColor.white};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    height: calc(100vh - 60px);
    width: 100%;
    border-radius: 0px;
  }
`;

const Title = styled.div`
  margin-top: 83px;
  font-size: ${(props) => props.theme.textSize.xLarge};
  text-align: center;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    margin-top: 70px;
    font-size: ${(props) => props.theme.textSize.large};
  }
`;

const SubTitle = styled.div`
  margin-top: 42px;
  margin-bottom: 63px;
  color: ${(props) => props.theme.textColor.gray};
  font-size: ${(props) => props.theme.textSize.medium};
  text-align: center;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
`;

const SignInButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    align-items: center;
    gap: 15px;
    width: 100%;
  }
`;

const SignInButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 67px;
  width: 468px;
  border: 1px solid ${(props) => props.theme.borderColor.gray};
  border-radius: 12px;
  font-size: ${(props) => props.theme.textSize.regular};
  cursor: pointer;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    height: 60px;
    width: 90%;
  }
`;

const SocialIcon = styled.img`
  height: 35px;
  width: auto;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    height: 25px;
  }
`;

function SignIn() {
  const handleRedirect = (provider: Provider) => {
    let authorizationUrl = "/";
    if (provider === Provider.GOOGLE)
      authorizationUrl = ENV.OAUTH_AUTHORIZATION_URL_GOOGLE;
    else if (provider === Provider.KAKAO)
      authorizationUrl = ENV.OAUTH_AUTHORIZATION_URL_KAKAO;

    setLocalStorage(localStorageKey.OAUTH_PROVIDER, provider);
    window.location.href = authorizationUrl;
  };

  return (
    <Container>
      <Title>
        남들보다 빠른 성장을 위한
        <br />
        나만의 SubTask!
      </Title>
      <SubTitle>지금 바로 시작해보세요.</SubTitle>
      <SignInButtonWrapper>
        <SignInButton onClick={() => handleRedirect(Provider.KAKAO)}>
          <SocialIcon src={socialKakao} />
          카카오톡으로 로그인
        </SignInButton>
        <SignInButton onClick={() => handleRedirect(Provider.GOOGLE)}>
          <SocialIcon src={socialGoogle} />
          구글로 로그인
        </SignInButton>
      </SignInButtonWrapper>
    </Container>
  );
}

export default SignIn;
