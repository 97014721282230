import styled from "styled-components";
import {
  useDeleteMyRecruitingProject,
  useGetMyRecruitingProjects,
  useStartMyRecruitingProject,
} from "../../../../hooks/queries/useProjectQuery";
import { Button, Modal, Pagination } from "antd";
import { useCallback, useEffect, useState } from "react";
import useQueryParams from "../../../../hooks/useQueryParams";
import { commonConstants } from "../../../../constants/common.constant";
import { isoStringToDateTypeA } from "../../../../helpers/date.helper";
import { useNavigate } from "react-router-dom";
import { pageRoute } from "../../../../constants/pageRoute.constant";
import hiIcon from "../../../../assets/icons/hi.png";
import noneIcon from "../../../../assets/icons/none.png";
import { scrollToTop } from "../../../../helpers/common.helper";
import { defaultList } from "../../../../types/common.type";
import useWindowSize from "../../../../hooks/useWindowSize";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 602px;
  width: 754px;
  padding-top: 47px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: ${({ theme }) => theme.backgroundColor.white};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    min-height: 602px;
    width: 100%;
    padding-top: 20px;
    border-radius: 0px;
  }
`;

const Title = styled.h1`
  width: 100%;
  padding: 0px 40px;
  font-size: ${({ theme }) => theme.textSize.large};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 0px;
    font-size: ${(props) => props.theme.textSize.normal};
  }
`;

const Description = styled.div`
  width: 100%;
  padding: 19px 40px 39px 40px;
  font-size: ${({ theme }) => theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.gray};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 15px 0px 30px 0px;
  }
`;

const List = styled.div`
  width: 640px;
  padding-bottom: 10px;
  font-size: ${(props) => props.theme.textSize.small};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
  }
`;

const ListTotalCount = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    margin-bottom: 10px;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  position: relative;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    padding-top: 10px;
  }
`;

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

const ListItemField = styled.div`
  display: flex;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 2px;
  }
`;

const ListItemFieldLabel = styled.div`
  width: 135px;
  flex-shrink: 0;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 100px;
  }
`;

const ListItemFieldValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
`;

const ListItemApplicantInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px;
  padding-left: 27px;
  background-color: ${({ theme }) => theme.backgroundColor.lightCoolGray};
  border-radius: 10px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    padding-left: 20px;
  }
`;

const ListItemApplicantInfoIcon = styled.img`
  height: 24px;
  width: auto;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    height: 20px;
  }
`;

const ListItemRegisteredAt = styled.div`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${(props) => props.theme.textColor.lighterGray};
`;

function MyRecruitingProjectList() {
  const { queryParams, updateQueryParams } = useQueryParams();
  const { isMobile } = useWindowSize();
  const [isHandleDeleteModalOpen, setIsHandleDeleteModalOpen] = useState(false);
  const [isHandleStartModalOpen, setIsHandleStartModalOpen] = useState(false);
  const [projectId, setProjectId] = useState<number | null>(null);
  const page = queryParams.page || commonConstants.PAGINATION_DEFAULT_PAGE;
  const pageSize = commonConstants.PAGINATION_DEFAULT_PAGE_SIZE;
  const { data: myRecruitingProjectList = defaultList, refetch } =
    useGetMyRecruitingProjects({ params: { page, pageSize } });
  const { rows: projects, count } = myRecruitingProjectList;
  const deleteMyRecruitingProjectMutation = useDeleteMyRecruitingProject();
  const startMyRecruitingProjectMutation = useStartMyRecruitingProject();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const handlePageChange = useCallback(
    (page: number) => {
      updateQueryParams({ page });
      scrollToTop();
    },
    [updateQueryParams]
  );

  const handleListItemClick = useCallback(
    (id: number) => {
      navigate(`${pageRoute.PROJECT_LIST}/${id}`);
    },
    [navigate]
  );

  const handleDelete = useCallback(() => {
    deleteMyRecruitingProjectMutation.mutate(projectId!);
    setIsHandleDeleteModalOpen(false);
  }, [deleteMyRecruitingProjectMutation, projectId]);

  const handleStart = useCallback(() => {
    startMyRecruitingProjectMutation.mutate(projectId!);
    setIsHandleStartModalOpen(false);
  }, [startMyRecruitingProjectMutation, projectId]);

  return (
    <Container>
      <Title>프로젝트 등록</Title>

      <Description>
        * 내가 등록한 프로젝트 목록이에요. 지원한 지원자들을 확인해주세요!
      </Description>

      <List>
        <ListTotalCount>전체 {count}건</ListTotalCount>
        {projects.map((project) => (
          <ListItem
            key={project.id}
            onClick={() => handleListItemClick(project.id)}
          >
            <ListItemField>
              <ListItemFieldLabel>프로젝트명</ListItemFieldLabel>
              <ListItemFieldValue>{project.projectName}</ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>플랫폼</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.projectPlatformListView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>모집직무</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.projectTasksJobView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>회의 장소</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.meetingChannelView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>목표 마감 기한</ListItemFieldLabel>
              <ListItemFieldValue>
                {isoStringToDateTypeA(project.desiredDeadlineAt)}
              </ListItemFieldValue>
            </ListItemField>
            {project.newApplicantCount > 0 && (
              <ListItemApplicantInfo>
                <ListItemApplicantInfoIcon src={hiIcon} />
                새로운 지원자가 {project.newApplicantCount}명 있어요.
              </ListItemApplicantInfo>
            )}
            {project.newApplicantCount === 0 && (
              <ListItemApplicantInfo>
                <ListItemApplicantInfoIcon src={noneIcon} />
                새로운 지원자가 없어요.
              </ListItemApplicantInfo>
            )}
            <ListItemRegisteredAt>
              등록일자: {isoStringToDateTypeA(project.createdAt)}
            </ListItemRegisteredAt>

            <ButtonWrapper>
              <Button
                color="primary"
                variant="outlined"
                htmlType="button"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setProjectId(project.id);
                  setIsHandleDeleteModalOpen(true);
                }}
                style={{ height: "44px", width: "110px" }}
              >
                삭제
              </Button>
              <Button
                color="primary"
                variant="solid"
                htmlType="button"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setProjectId(project.id);
                  setIsHandleStartModalOpen(true);
                }}
                style={{ height: "44px", width: "110px" }}
              >
                프로젝트 시작
              </Button>
            </ButtonWrapper>
          </ListItem>
        ))}
      </List>

      <Modal
        open={isHandleDeleteModalOpen}
        onOk={handleDelete}
        okText="삭제"
        onCancel={() => setIsHandleDeleteModalOpen(false)}
        cancelText="취소"
        width={400}
        centered={isMobile ? true : false}
      >
        <ModalContent>
          프로젝트를 취소하면 등록한 프로젝트가 삭제돼요.
          <br />
          그래도 삭제하시겠어요?
        </ModalContent>
      </Modal>

      <Modal
        open={isHandleStartModalOpen}
        onOk={handleStart}
        okText="시작"
        onCancel={() => setIsHandleStartModalOpen(false)}
        cancelText="취소"
        width={400}
        centered={isMobile ? true : false}
      >
        <ModalContent>
          프로젝트를 시작하면 진행단계로 넘어가요.
          <br />
          바로 시작하시겠어요?
        </ModalContent>
      </Modal>

      <Pagination
        style={{ paddingBottom: 35, marginTop: "auto" }}
        align="center"
        defaultCurrent={1}
        total={count}
        pageSize={Number(pageSize)}
        current={Number(page)}
        onChange={handlePageChange}
      />
    </Container>
  );
}

export default MyRecruitingProjectList;
