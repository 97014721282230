import styled from "styled-components";
import { useGetMyAppliedProjects } from "../../../../hooks/queries/useProjectQuery";
import { Pagination } from "antd";
import { useCallback, useEffect } from "react";
import useQueryParams from "../../../../hooks/useQueryParams";
import { commonConstants } from "../../../../constants/common.constant";
import { isoStringToDateTypeA } from "../../../../helpers/date.helper";
import goodIcon from "../../../../assets/icons/good.png";
import failIcon from "../../../../assets/icons/fail.png";
import ingIcon from "../../../../assets/icons/ing.png";
import { scrollToTop } from "../../../../helpers/common.helper";
import { defaultList } from "../../../../types/common.type";
import { ProjectWorkerStatus } from "../../../../types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 602px;
  width: 754px;
  padding-top: 47px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: ${({ theme }) => theme.backgroundColor.white};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    min-height: 602px;
    width: 100%;
    padding-top: 20px;
    border-radius: 0px;
  }
`;

const Title = styled.h1`
  width: 100%;
  padding: 0px 40px;
  font-size: ${({ theme }) => theme.textSize.large};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 0px;
    font-size: ${(props) => props.theme.textSize.normal};
  }
`;

const Description = styled.div`
  width: 100%;
  padding: 19px 40px 39px 40px;
  font-size: ${({ theme }) => theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.gray};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 15px 0px 30px 0px;
  }
`;

const List = styled.div`
  width: 640px;
  padding-bottom: 10px;
  font-size: ${(props) => props.theme.textSize.small};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
  }
`;

const ListTotalCount = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    margin-bottom: 10px;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  position: relative;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  // &:hover { // TODO
  //   cursor: pointer;
  // }
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 10px;
  }
`;

const ListItemField = styled.div`
  display: flex;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 2px;
  }
`;

const ListItemFieldLabel = styled.div`
  width: 135px;
  flex-shrink: 0;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 100px;
  }
`;

const ListItemFieldValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
`;

const ListItemApplicantInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px;
  padding-left: 27px;
  background-color: ${({ theme }) => theme.backgroundColor.lightCoolGray};
  border-radius: 10px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    padding-left: 20px;
  }
`;

const ListItemApplicantInfoIcon = styled.img`
  height: 24px;
  width: auto;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    height: 20px;
  }
`;

const ListItemRegisteredAt = styled.div`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${(props) => props.theme.textColor.lighterGray};
`;

function MyAppliedProjectList() {
  const { queryParams, updateQueryParams } = useQueryParams();
  const page = queryParams.page || commonConstants.PAGINATION_DEFAULT_PAGE;
  const pageSize = commonConstants.PAGINATION_DEFAULT_PAGE_SIZE;
  const { data: myAppliedProjectList = defaultList, refetch } =
    useGetMyAppliedProjects({ params: { page, pageSize } });
  const { rows: projects, count } = myAppliedProjectList;

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const handlePageChange = useCallback(
    (page: number) => {
      updateQueryParams({ page });
      scrollToTop();
    },
    [updateQueryParams]
  );

  // const handleListItemClick = useCallback((id: number) => {navigate(`${pageRoute.PROJECT_LIST}/${id}`);}, [navigate]);

  return (
    <Container>
      <Title>프로젝트 지원</Title>

      <Description>* 내가 지원한 프로젝트 목록이에요.</Description>

      <List>
        <ListTotalCount>전체 {count}건</ListTotalCount>
        {projects.map((project) => (
          <ListItem
            key={project.id}
            // onClick={() => handleListItemClick(project.id)} // TODO
          >
            <ListItemField>
              <ListItemFieldLabel>프로젝트명</ListItemFieldLabel>
              <ListItemFieldValue>{project.projectName}</ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>플랫폼</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.projectPlatformListView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>모집직무</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.projectTasksJobView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>회의 장소</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.meetingChannelView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>목표 마감 기한</ListItemFieldLabel>
              <ListItemFieldValue>
                {isoStringToDateTypeA(project.desiredDeadlineAt)}
              </ListItemFieldValue>
            </ListItemField>
            {project.projectWorkerStatus === ProjectWorkerStatus.APPLIED && (
              <ListItemApplicantInfo>
                <ListItemApplicantInfoIcon src={ingIcon} />
                답변을 기다리고 있어요.
              </ListItemApplicantInfo>
            )}
            {project.projectWorkerStatus === ProjectWorkerStatus.REJECTED && (
              <ListItemApplicantInfo>
                <ListItemApplicantInfoIcon src={failIcon} />
                이번엔 같이할 수 없을 것 같아요.
              </ListItemApplicantInfo>
            )}
            {project.projectWorkerStatus === ProjectWorkerStatus.ACCEPTED && (
              <ListItemApplicantInfo>
                <ListItemApplicantInfoIcon src={goodIcon} />
                승낙됐어요!
              </ListItemApplicantInfo>
            )}
            <ListItemRegisteredAt>
              등록일자: {isoStringToDateTypeA(project.createdAt)}
            </ListItemRegisteredAt>
          </ListItem>
        ))}
      </List>

      <Pagination
        style={{ paddingBottom: 35, marginTop: "auto" }}
        align="center"
        defaultCurrent={1}
        total={count}
        pageSize={Number(pageSize)}
        current={Number(page)}
        onChange={handlePageChange}
      />
    </Container>
  );
}

export default MyAppliedProjectList;
