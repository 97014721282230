import { useParams } from "react-router-dom";
import { useGetMyCompleteProjectDetail } from "../../../../hooks/queries/useProjectQuery";
import styled from "styled-components";
import {
  isoStringToDateTypeA,
  isoStringToDateTypeB,
} from "../../../../helpers/date.helper";
import {
  Button,
  Form,
  FormInstance,
  Progress,
  Space,
  Tooltip,
  Upload,
  UploadFile,
} from "antd";
import { jobViewer } from "../../../../types/account.type";
import {
  defaultMyCompleteProjectDetail,
  meetingChannelViewer,
  projectPlatformListViewer,
  projectWorkerProgressStatusViewer,
} from "../../../../types/project.type";
import questionIcon from "../../../../assets/icons/question.png";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { isHandleBackModalOpenState } from "../../../../atoms/modalState";
import { getAbsoluteUrl } from "../../../../helpers/common.helper";
import { commonConstants } from "../../../../constants/common.constant";
import useWindowSize from "../../../../hooks/useWindowSize";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 754px;
  padding-bottom: 50px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: ${(props) => props.theme.backgroundColor.white};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
    border-radius: 0px;
  }
`;

const Title = styled.h1`
  width: 100%;
  padding: 34px 32px;
  font-size: ${(props) => props.theme.textSize.large};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 20px 0px 30px 0px;
    font-size: ${(props) => props.theme.textSize.normal};
  }
`;

const StyledForm = styled(Form)<{ form?: FormInstance }>`
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 85%;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 0px 58px;
  font-size: ${(props) => props.theme.textSize.small};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 30px;
    padding: 0px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 48px;
`;

const ItemField = styled.div``;

const ItemValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
  white-space: pre-line;
`;

const ItemFieldDescription = styled.div`
  color: ${(props) => props.theme.textColor.lightestGray};
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const ProjectWorkerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 46px;
  width: 100%;
  & > div {
    flex: 1;
  }
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 3px;
    height: 30px;
    font-size: ${(props) => props.theme.textSize.tiny};
  }
`;

const ProjectWorkerField = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    justify-content: center;
  }
`;

const TooltipIcon = styled.img`
  height: 20px;
  width: auto;
`;

const ProjectWorkerColumn = styled.div`
  color: ${({ theme }) => theme.textColor.gray};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

function MyCompleteProjectDetail() {
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useWindowSize();
  const projectId = id!;
  const { data: myCompleteProjectDetail = defaultMyCompleteProjectDetail } =
    useGetMyCompleteProjectDetail(projectId);
  const [fileList, setFileList] = useState<(UploadFile & { id?: number })[]>(
    []
  );
  const setIsHandleBackModalOpen = useSetRecoilState(
    isHandleBackModalOpenState
  );

  useEffect(() => {
    const { introductionSimpleMedias } = myCompleteProjectDetail;
    if (introductionSimpleMedias) {
      const fileList: UploadFile[] = introductionSimpleMedias.map((el) => {
        const { id, mediaUrl: url, originalFileName: name } = el;
        const uid = String(id);
        const status = "done";

        return { id, uid, name, status, url };
      });
      setFileList(fileList);
    }
  }, [myCompleteProjectDetail]);

  const {
    projectName,
    desiredDeadlineAt,
    totalCompletionRate,
    projectIntroductionUrlList,
    projectCompletedAt,
    simpleProjectWorkers,
    projectPlatformList,
    meetingChannel,
    meetingSchedule,
    preferredMeetingRegionView,
  } = myCompleteProjectDetail;
  const [projectIntroductionUrl] = projectIntroductionUrlList;

  return (
    <Container>
      <Title>프로젝트 완료</Title>

      <StyledForm>
        <ItemsWrapper>
          <Item>
            <ItemField>프로젝트명</ItemField>
            <ItemValue>{projectName}</ItemValue>
          </Item>
          <Item>
            <ItemField>플랫폼</ItemField>
            <ItemValue>
              {projectPlatformListViewer(projectPlatformList)}
            </ItemValue>
          </Item>
          <Item style={{ gap: "0px" }}>
            <ItemField>전체 달성율</ItemField>
            <ItemValue>
              <Progress percent={totalCompletionRate * 100} showInfo={false} />
              {totalCompletionRate * 100}% 달성했어요.
            </ItemValue>
          </Item>
          <Item>
            <ItemField>목표 마감 일자</ItemField>
            <ItemValue>{isoStringToDateTypeA(desiredDeadlineAt)}</ItemValue>
          </Item>
          <Item>
            <ItemField>실제 완료 일자</ItemField>
            <ItemValue>{isoStringToDateTypeA(projectCompletedAt)}</ItemValue>
          </Item>
          <Item>
            <ItemField>담당자별 달성 점수</ItemField>
            <ProjectWorkerWrapper>
              <ProjectWorkerField>담당 직무</ProjectWorkerField>
              <ProjectWorkerField>이름</ProjectWorkerField>
              <ProjectWorkerField>마감 기한</ProjectWorkerField>
              <ProjectWorkerField>진행 상태</ProjectWorkerField>
              <ProjectWorkerField>
                달성 점수
                {!isMobile && (
                  <Tooltip
                    placement="bottomLeft"
                    title="마감 기한까지 진행 상태를 '완료' 처리하면 달성 점수가 오릅니다!"
                    arrow={{ pointAtCenter: true }}
                  >
                    <TooltipIcon src={questionIcon} />
                  </Tooltip>
                )}
              </ProjectWorkerField>
            </ProjectWorkerWrapper>
            {simpleProjectWorkers.map((simpleProjectWorker) => {
              const {
                id,
                job,
                name,
                projectWorkerDeadlineAt,
                projectWorkerProgressStatus,
                score,
              } = simpleProjectWorker;

              return (
                <ProjectWorkerWrapper key={id}>
                  <ProjectWorkerColumn>{jobViewer(job)}</ProjectWorkerColumn>
                  <ProjectWorkerColumn>{name}</ProjectWorkerColumn>
                  <ProjectWorkerColumn>
                    {isoStringToDateTypeB(projectWorkerDeadlineAt)}
                  </ProjectWorkerColumn>
                  <ProjectWorkerColumn>
                    {projectWorkerProgressStatusViewer(
                      projectWorkerProgressStatus
                    )}
                  </ProjectWorkerColumn>
                  <ProjectWorkerColumn>{score}</ProjectWorkerColumn>
                </ProjectWorkerWrapper>
              );
            })}
          </Item>
          <Item>
            <ItemField>회의 장소</ItemField>
            <ItemValue>{meetingChannelViewer(meetingChannel)}</ItemValue>
          </Item>
          {preferredMeetingRegionView && (
            <Item>
              <ItemField>회의 선호 지역</ItemField>
              <ItemValue>{preferredMeetingRegionView}</ItemValue>
            </Item>
          )}
          <Item>
            <ItemField>회의 일정</ItemField>
            <ItemValue>{meetingSchedule}</ItemValue>
          </Item>
          <Item>
            <ItemField>
              프로젝트 알리기
              <ItemFieldDescription>
                * 어떤 프로젝트를 진행했는지 문서 또는 URL을 통해 보여주세요.
              </ItemFieldDescription>
            </ItemField>
            {fileList.length > 0 && (
              <Upload
                fileList={fileList}
                showUploadList={{ showRemoveIcon: false }}
                listType="text"
                onRemove={() => false}
              />
            )}
            {projectIntroductionUrl && (
              <ItemValue>
                <a
                  href={getAbsoluteUrl(projectIntroductionUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {projectIntroductionUrl}
                </a>
              </ItemValue>
            )}
            {fileList.length === 0 && !projectIntroductionUrl && (
              <ItemValue>-</ItemValue>
            )}
          </Item>
        </ItemsWrapper>

        <Space
          style={{
            width: "100%",
            justifyContent: "center",
            paddingTop: "30px",
          }}
          size="middle"
        >
          <Button
            type="default"
            htmlType="button"
            onClick={() => setIsHandleBackModalOpen(true)}
            style={{ height: "44px", width: "110px" }}
          >
            이전
          </Button>
        </Space>
      </StyledForm>
    </Container>
  );
}

export default MyCompleteProjectDetail;
