import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams]
  );

  const updateQueryParams = useCallback(
    (updates: { [key: string]: string | number }, reset = false) => {
      const newSearchParams = reset
        ? new URLSearchParams()
        : new URLSearchParams(searchParams);

      Object.entries(updates).forEach(([key, value]) => {
        if (value) newSearchParams.set(key, String(value));
      });

      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return { queryParams, updateQueryParams };
}

export default useQueryParams;
