import styled from "styled-components";
import { useGetMyCompleteProjects } from "../../../../hooks/queries/useProjectQuery";
import { Pagination } from "antd";
import { useCallback, useEffect } from "react";
import useQueryParams from "../../../../hooks/useQueryParams";
import { commonConstants } from "../../../../constants/common.constant";
import { isoStringToDateTypeA } from "../../../../helpers/date.helper";
import { useNavigate } from "react-router-dom";
import { pageRoute } from "../../../../constants/pageRoute.constant";
import { scrollToTop } from "../../../../helpers/common.helper";
import { defaultList } from "../../../../types/common.type";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 602px;
  width: 754px;
  padding-top: 47px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: ${({ theme }) => theme.backgroundColor.white};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    min-height: 602px;
    width: 100%;
    padding-top: 20px;
    border-radius: 0px;
  }
`;

const Title = styled.h1`
  width: 100%;
  padding: 0px 40px;
  font-size: ${({ theme }) => theme.textSize.large};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 0px;
    font-size: ${(props) => props.theme.textSize.normal};
  }
`;

const List = styled.div`
  width: 640px;
  padding-bottom: 10px;
  font-size: ${(props) => props.theme.textSize.small};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
  }
`;

const ListTotalCount = styled.div`
  margin-top: 22px;
  margin-bottom: 20px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  position: relative;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 10px;
  }
`;

const ListItemField = styled.div`
  display: flex;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 2px;
  }
`;

const ListItemFieldLabel = styled.div`
  width: 135px;
  flex-shrink: 0;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 100px;
  }
`;

const ListItemFieldValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
`;

function MyCompleteProjectList() {
  const { queryParams, updateQueryParams } = useQueryParams();
  const page = queryParams.page || commonConstants.PAGINATION_DEFAULT_PAGE;
  const pageSize = commonConstants.PAGINATION_DEFAULT_PAGE_SIZE;
  const { data: myCompleteProjectList = defaultList, refetch } =
    useGetMyCompleteProjects({ params: { page, pageSize } });
  const { rows: projects, count } = myCompleteProjectList;
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const handlePageChange = useCallback(
    (page: number) => {
      updateQueryParams({ page });
      scrollToTop();
    },
    [updateQueryParams]
  );

  const handleListItemClick = useCallback(
    (id: number) => {
      navigate(`${pageRoute.MY_PROJECT_COMPLETE_PROJECT_LIST}/${id}`);
    },
    [navigate]
  );

  return (
    <Container>
      <Title>프로젝트 완료</Title>

      <List>
        <ListTotalCount>전체 {count}건</ListTotalCount>
        {projects.map((project) => (
          <ListItem
            key={project.id}
            onClick={() => handleListItemClick(project.id)}
          >
            <ListItemField>
              <ListItemFieldLabel>프로젝트명</ListItemFieldLabel>
              <ListItemFieldValue>{project.projectName}</ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>실제 완료 일자</ListItemFieldLabel>
              <ListItemFieldValue>
                {isoStringToDateTypeA(project.projectCompletedAt)}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>전체 달성율</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.totalCompletionRate * 100}%
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>목표 마감 일자</ListItemFieldLabel>
              <ListItemFieldValue>
                {isoStringToDateTypeA(project.desiredDeadlineAt)}
              </ListItemFieldValue>
            </ListItemField>
          </ListItem>
        ))}
      </List>

      <Pagination
        style={{ paddingBottom: 35, marginTop: "auto" }}
        align="center"
        defaultCurrent={1}
        total={count}
        pageSize={Number(pageSize)}
        current={Number(page)}
        onChange={handlePageChange}
      />
    </Container>
  );
}

export default MyCompleteProjectList;
