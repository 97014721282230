import styled from "styled-components";
import { Button, Input } from "antd";
import { useState } from "react";
import { commonConstants } from "../../../constants/common.constant";
import noticeIcon from "../../../assets/icons/notice.png";
import { useSetRecoilState } from "recoil";
import { isHandleBackModalOpenState } from "../../../atoms/modalState";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 58px 0px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 40px 0px;
  }
`;

const OverlayMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 30%;
  width: 50%;
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: 16px;
  box-shadow: 0 0 8px 0 #ccd1d6;
  z-index: 500;
  color: ${(props) => props.theme.textColor.gray};
  text-align: center;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 80%;
  }
`;

const NoticeIcon = styled.img`
  height: 20px;
  width: auto;
`;

const Container = styled.div`
  width: 637px;
  padding: 33px 26px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.lightCoolGray};
  filter: blur(2px);
  pointer-events: none;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 100%;
    padding: 20px;
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.textSize.medium};
  margin-bottom: 30px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    font-size: ${({ theme }) => theme.textSize.normal};
    margin-bottom: 30px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  font-size: ${(props) => props.theme.textSize.small};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 48px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 5px;
  }
`;

const ItemField = styled.div<{ required?: boolean }>`
  ${({ required }) =>
    required &&
    `&::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }`}
`;

const ItemValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
  white-space: pre-line;
`;

const StyledTextArea = styled(Input.TextArea)`
  font-size: ${(props) => props.theme.textSize.tiny};
  padding: 16px 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
`;

function SignUpPrompt() {
  const [memo, setMemo] = useState("");
  const setIsHandleBackModalOpen = useSetRecoilState(
    isHandleBackModalOpenState
  );

  return (
    <Wrapper>
      <OverlayMessage>
        <NoticeIcon src={noticeIcon} />
        회원가입 후
        <br />
        프로젝트 지원이 가능합니다.
      </OverlayMessage>

      <Container>
        <Title>나의 지원 내용</Title>

        <ItemsWrapper>
          <Item>
            <ItemField>이메일</ItemField>
            <ItemValue>{}</ItemValue>
          </Item>

          <Item>
            <ItemField>직무</ItemField>
            <ItemValue>{}</ItemValue>
          </Item>

          <Item>
            <ItemField>스킬</ItemField>
            <ItemValue>{}</ItemValue>
          </Item>

          <Item>
            <ItemField>경력</ItemField>
            <ItemValue>{}</ItemValue>
          </Item>

          <Item>
            <ItemField required={true}>남길 메모</ItemField>

            <StyledTextArea
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              placeholder="메모를 남겨주세요."
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
          </Item>
        </ItemsWrapper>
      </Container>

      <ButtonWrapper>
        <Button
          type="default"
          htmlType="button"
          onClick={() => setIsHandleBackModalOpen(true)}
          style={{ height: "44px", width: "110px" }}
        >
          이전
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default SignUpPrompt;
