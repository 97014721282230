import { DefaultTheme } from "styled-components";

interface ITheme extends DefaultTheme {
  textColor: {
    black: string;
    lightestGray: string;
    lighterGray: string;
    gray: string;
    darkerGray: string;
    red: string;
    white: string;
    lighterBlue: string;
    blue: string;
    deepBlue: string;
    green: string;
  };
  textSize: {
    teeny: string;
    tiny: string;
    small: string;
    normal: string;
    medium: string;
    large: string;
    xLarge: string;
    // xxLarge: string
    // huge: string
  };
  fontWeight: {
    thin: number;
    extraLight: number;
    light: number;
    regular: number;
    medium: number;
    semiBold: number;
    bold: number;
    extraBold: number;
    black: number;
  };
  backgroundColor: {
    lightCoolGray: string;
    coolGrayBlue: string;
    gray: string;
    white: string;
    softBlue: string;
    blue: string;
    black: string;
    lightGreen: string;
  };
  borderColor: {
    gray: string;
    red: string;
    lighterGray: string;
  };
}

export const theme: ITheme = {
  textColor: {
    black: "#000000",
    lightestGray: "#AFAFAF",
    lighterGray: "#A5A5A5",
    gray: "#8c8c8c",
    darkerGray: "#5E656E",
    red: "#ff0000",
    white: "#ffffff",
    lighterBlue: "#2086FE",
    blue: "#007aff",
    deepBlue: "#1565c0",
    green: "#00aa44",
  },
  textSize: {
    teeny: "12px",
    tiny: "14px",
    small: "16px",
    normal: "18px",
    medium: "20px",
    large: "24px",
    xLarge: "28px",
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  backgroundColor: {
    lightCoolGray: "#fafbfc",
    coolGrayBlue: "#f6f7f9",
    gray: "#8c8c8c",
    white: "#ffffff",
    softBlue: "#e3f2fd",
    blue: "#007aff",
    black: "#000000",
    lightGreen: "#e7fbef",
  },
  borderColor: {
    lighterGray: "#DCDEE0",
    gray: "#cFd6d9",
    red: "#d64c4c",
  },
};
