const nodeEnvs = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
} as const;
const NODE_ENV_ARRAY = Object.values(nodeEnvs);

export const commonConstants = {
  // 환경변수
  nodeEnvs,
  NODE_ENV_ARRAY,
  // pagination
  PAGINATION_DEFAULT_PAGE: "1",
  PAGINATION_DEFAULT_PAGE_SIZE: "10",
  // 반응형 디자인
  BREAKPOINT_MOBILE_MAX: 768,
  BREAKPOINT_DESKTOP_MIN: 769,
  DESKTOP_SIDEBAR_NO_OVERLAY: 1260,
};
