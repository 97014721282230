import styled from "styled-components";
import {
  useApplyForProjectById,
  useGetMyApplicationInfoByProjectId,
} from "../../../hooks/queries/useProjectQuery";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  isProfileCompleteSelector,
  userState,
} from "../../../atoms/accountState";
import { jobViewer } from "../../../types";
import { Button, Input, message, Modal } from "antd";
import { isHandleBackModalOpenState } from "../../../atoms/modalState";
import { useCallback, useEffect, useState } from "react";
import { isGlobalLoadingState } from "../../../atoms/loadingState";
import { commonConstants } from "../../../constants/common.constant";
import noticeIcon from "../../../assets/icons/notice.png";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 58px 0px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 40px 0px;
  }
`;

const OverlayMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 30%;
  width: 50%;
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: 16px;
  box-shadow: 0 0 8px 0 #ccd1d6;
  z-index: 500;
  color: ${(props) => props.theme.textColor.gray};
  text-align: center;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 80%;
  }
`;

const NoticeIcon = styled.img`
  height: 20px;
  width: auto;
`;

const Container = styled.div<{ $isBlurred: boolean }>`
  width: 637px;
  padding: 33px 26px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.lightCoolGray};
  filter: ${({ $isBlurred }) => ($isBlurred ? "blur(2px)" : "none")};
  pointer-events: ${({ $isBlurred }) => ($isBlurred ? "none" : "auto")};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 100%;
    padding: 20px;
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.textSize.medium};
  margin-bottom: 30px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    font-size: ${({ theme }) => theme.textSize.normal};
    margin-bottom: 30px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  font-size: ${(props) => props.theme.textSize.small};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 48px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 5px;
  }
`;

const ItemField = styled.div<{ required?: boolean }>`
  ${({ required }) =>
    required &&
    `&::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }`}
`;

const ItemValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
  white-space: pre-line;
`;

const StyledTextArea = styled(Input.TextArea)`
  font-size: ${(props) => props.theme.textSize.tiny};
  padding: 16px 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
`;

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

interface IProps {
  projectId: string;
  isMobile: boolean;
}

function MyApplicationInfo({ projectId, isMobile }: IProps) {
  const [memo, setMemo] = useState("");
  const isProfileComplete = useRecoilValue(isProfileCompleteSelector);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] =
    useRecoilState(isGlobalLoadingState);
  const user = useRecoilValue(userState)!;
  const setIsHandleBackModalOpen = useSetRecoilState(
    isHandleBackModalOpenState
  );
  const {
    data: myApplicationInfo,
    isFetching,
    refetch,
  } = useGetMyApplicationInfoByProjectId({
    projectId,
  });
  const { mutate, isLoading, isSuccess } = useApplyForProjectById();

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading && !isGlobalLoading) setIsGlobalLoading(true);
    if (!isLoading && isGlobalLoading) setIsGlobalLoading(false);
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch]);

  const handleSubmit = useCallback(() => {
    setIsSubmitModalOpen(false);
    mutate({ projectId, data: { memo, projectId } });
    // TODO onSuccess 시 지원 현황으로 페이지 이동. 작업 시 refetch(); useEffect 제거.
  }, [mutate, projectId, memo]);

  const isBlurred = !isFetching && !myApplicationInfo && !isProfileComplete;
  const email = myApplicationInfo?.email || user.email!;
  const job = myApplicationInfo?.job || user.job!;
  const skillNameList = myApplicationInfo?.skillNameList || user.skillNameList!;
  const yearOfJob = myApplicationInfo?.yearOfJob || user.yearOfJob!;

  return (
    <Wrapper>
      {isBlurred && (
        <OverlayMessage>
          <NoticeIcon src={noticeIcon} />
          마이페이지에서 내 정보 입력 시
          <br />
          프로젝트 지원이 가능해요.
        </OverlayMessage>
      )}

      <Container $isBlurred={isBlurred}>
        <Title>나의 지원 내용</Title>

        <ItemsWrapper>
          <Item>
            <ItemField>이메일</ItemField>
            <ItemValue>{email}</ItemValue>
          </Item>

          <Item>
            <ItemField>직무</ItemField>
            <ItemValue>{jobViewer(job)}</ItemValue>
          </Item>

          <Item>
            <ItemField>스킬</ItemField>
            <ItemValue>{skillNameList.join(", ")}</ItemValue>
          </Item>

          <Item>
            <ItemField>경력</ItemField>
            <ItemValue>{yearOfJob ? yearOfJob + "년" : ""}</ItemValue>
          </Item>

          {myApplicationInfo && (
            <Item>
              <ItemField>남긴 메모</ItemField>
              <ItemValue>{myApplicationInfo.memo}</ItemValue>
            </Item>
          )}

          {!isFetching && !myApplicationInfo && (
            <Item>
              <ItemField required={true}>남길 메모</ItemField>

              <StyledTextArea
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                placeholder="메모를 남겨주세요."
                autoSize={{ minRows: 5, maxRows: 5 }}
              />
            </Item>
          )}
        </ItemsWrapper>
      </Container>

      <ButtonWrapper>
        <Button
          type="default"
          htmlType="button"
          onClick={() => setIsHandleBackModalOpen(true)}
          style={{ height: "44px", width: "110px" }}
        >
          이전
        </Button>
        {!isFetching && !myApplicationInfo && isProfileComplete && (
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              if (!memo) message.error("메모를 남겨주세요.");
              else setIsSubmitModalOpen(true);
            }}
            style={{ height: "44px", width: "110px" }}
          >
            지원
          </Button>
        )}
      </ButtonWrapper>

      <Modal
        open={isSubmitModalOpen}
        onOk={handleSubmit}
        okText="네"
        onCancel={() => setIsSubmitModalOpen(false)}
        cancelText="취소"
        width={400}
        centered={isMobile ? true : false}
      >
        <ModalContent>프로젝트에 지원하시겠습니까?</ModalContent>
      </Modal>
    </Wrapper>
  );
}

export default MyApplicationInfo;
