import { Routes, Route, Navigate } from "react-router-dom";
import ProjectList from "../components/pages/ProjectList/ProjectList";
import SignIn from "../components/pages/SignIn/SignIn";
import SignUp from "../components/pages/SignUp/SignUp";
import ProjectRegister from "../components/pages/ProjectRegister/ProjectRegister";
import Mypage from "../components/pages/Mypage/Mypage";
import { pageRoute } from "../constants/pageRoute.constant";
import OauthCallback from "../components/pages/OauthCallback/OauthCallback";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";
import ProjectDetail from "../components/pages/ProjectDetail/ProjectDetail";
import ProfileCompleteRoutes from "./ProfileCompleteRoutes";
import MyRecruitingProjectList from "../components/pages/MyProject/MyRecruitingProjectList/MyRecruitingProjectList";
import MyAppliedProjectList from "../components/pages/MyProject/MyAppliedProjectList/MyAppliedProjectList";
import MyInProgressProjectList from "../components/pages/MyProject/MyInProgressProjectList/MyInProgressProjectList";
import MyCompleteProjectList from "../components/pages/MyProject/MyCompleteProjectList/MyCompleteProjectList";
import ProjectEdit from "../components/pages/ProjectEdit/ProjectEdit";
import MyInProgressProjectDetail from "../components/pages/MyProject/MyInProgressProjectDetail/MyInProgressProjectDetail";
import MyCompleteProjectDetail from "../components/pages/MyProject/MyCompleteProjectDetail/MyCompleteProjectDetail";

function AppRoutes() {
  return (
    <Routes>
      <Route
        path={pageRoute.HOME}
        element={<Navigate to={pageRoute.PROJECT_LIST} replace />}
      />
      <Route path={pageRoute.PROJECT_LIST} element={<ProjectList />} />
      <Route path={pageRoute.PROJECT_DETAIL} element={<ProjectDetail />} />

      {/* 인증이 안된 경우에만 진입할 수 있는 라우트 */}
      <Route element={<GuestRoutes />}>
        <Route path={pageRoute.SIGN_IN} element={<SignIn />} />
        <Route path={pageRoute.SIGN_UP} element={<SignUp />} />
        <Route path={pageRoute.OAUTH_CALLBACK} element={<OauthCallback />} />
      </Route>

      {/* 인증이 된 경우에만 진입할 수 있는 라우트 */}
      <Route element={<PrivateRoutes />}>
        <Route
          path={pageRoute.PROJECT_REGISTER}
          element={<ProjectRegister />}
        />
        <Route path={pageRoute.PROJECT_EDIT} element={<ProjectEdit />} />
        <Route path={pageRoute.MYPAGE} element={<Mypage />} />
        <Route
          path={pageRoute.MY_PROJECT}
          element={
            <Navigate
              to={pageRoute.MY_PROJECT_RECRUITING_PROJECT_LIST}
              replace
            />
          }
        />
        <Route
          path={pageRoute.MY_PROJECT_RECRUITING_PROJECT_LIST}
          element={<MyRecruitingProjectList />}
        />
        <Route
          path={pageRoute.MY_PROJECT_APPLIED_PROJECT_LIST}
          element={<MyAppliedProjectList />}
        />
        <Route
          path={pageRoute.MY_PROJECT_IN_PROGRESS_PROJECT_LIST}
          element={<MyInProgressProjectList />}
        />
        <Route
          path={pageRoute.MY_PROJECT_IN_PROGRESS_PROJECT_DETAIL}
          element={<MyInProgressProjectDetail />}
        />
        <Route
          path={pageRoute.MY_PROJECT_COMPLETE_PROJECT_LIST}
          element={<MyCompleteProjectList />}
        />
        <Route
          path={pageRoute.MY_PROJECT_COMPLETE_PROJECT_DETAIL}
          element={<MyCompleteProjectDetail />}
        />
      </Route>

      {/* 인증 및 내 정보가 입력된 경우에만 진입할 수 있는 라우트 */}
      <Route element={<ProfileCompleteRoutes />}>{/* 미사용 */}</Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default AppRoutes;
