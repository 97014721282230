import styled from "styled-components";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Modal,
  Cascader,
} from "antd";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isHandleBackModalOpenState } from "../../../atoms/modalState";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { koreaRegionCascaderOptions } from "../../../constants/koreaRegion.constant";
import {
  jobOptions,
  meetingChannelOptions,
  platformOptions,
} from "../../../constants/selectProps.constant";
import {
  isProfileCompleteSelector,
  userState,
} from "../../../atoms/accountState";
import { useNavigate } from "react-router-dom";
import { pageRoute } from "../../../constants/pageRoute.constant";
import { Job, jobViewer, MeetingChannel } from "../../../types";
import { useCreateProject } from "../../../hooks/queries/useProjectQuery";
import { isGlobalLoadingState } from "../../../atoms/loadingState";
import { commonConstants } from "../../../constants/common.constant";
import useWindowSize from "../../../hooks/useWindowSize";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 754px;
  padding-top: 34px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: ${(props) => props.theme.backgroundColor.white};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 100%;
    padding-top: 20px;
    border-radius: 00px;
  }
`;

const Title = styled.h1`
  width: 100%;
  padding: 0px 32px;
  font-size: ${(props) => props.theme.textSize.large};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 0px;
    font-size: ${(props) => props.theme.textSize.normal};
  }
`;

const StyledLabel = styled.span`
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const StyledInput = styled(Input)`
  height: 52px;
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const StyledSelect = styled(Select)`
  height: 52px;
  font-size: ${(props) => props.theme.textSize.tiny};
  .ant-select-selection-item {
    font-size: ${(props) => props.theme.textSize.tiny};
  }
`;

const ProjectTaskWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const MyTaskJob = styled.div`
  height: 32px;
  width: 120px;
  line-height: 32px;
  padding: 0px 12px;
`;

const StyledCascader = styled(Cascader)`
  height: 52px;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 52px;
  width: 100%;
`;

const StyledTextArea = styled(Input.TextArea)`
  font-size: ${(props) => props.theme.textSize.tiny};
  padding: 16px 12px;
`;

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

interface IFormInput {
  projectName: string;
  projectPlatformList: string[];
  projectTaskList?: IProjectTask[];
  myTaskRecruitsNumber: number;
  myTaskJobDescription?: string;
  meetingChannel: string;
  preferredMeetingRegionInfo?: string[];
  meetingSchedule?: string;
  desiredDeadlineAt: string;
  description: string;
}

interface IProjectTask {
  job: Job;
  recruitsNumber: number;
  jobDescription: string;
}

function ProjectRegister() {
  const [form] = Form.useForm<IFormInput>();
  const { isMobile } = useWindowSize();
  const [isOfflineIncluded, setIsOfflineIncluded] = useState(false);
  const isProfileComplete = useRecoilValue(isProfileCompleteSelector);
  const user = useRecoilValue(userState);
  const [isGlobalLoading, setIsGlobalLoading] =
    useRecoilState(isGlobalLoadingState);
  const setIsHandleBackModalOpen = useSetRecoilState(
    isHandleBackModalOpenState
  );
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const navigate = useNavigate();
  const { mutate, isLoading } = useCreateProject();
  // useNavigationBlocker(); // TODO

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading && !isGlobalLoading) setIsGlobalLoading(true);
    if (!isLoading && isGlobalLoading) setIsGlobalLoading(false);
    // eslint-disable-next-line
  }, [isLoading]);

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleMeetingChannelChange = (value: unknown) => {
    if (
      value === MeetingChannel.OFFLINE ||
      value === MeetingChannel.OFFLINE_ONLINE
    )
      setIsOfflineIncluded(true);
    else setIsOfflineIncluded(false);
  };

  const handleRegister = () => {
    setIsRegisterModalOpen(false);
    const values = form.getFieldsValue();
    const {
      projectName,
      projectPlatformList,
      projectTaskList,
      myTaskRecruitsNumber,
      myTaskJobDescription,
      meetingChannel,
      preferredMeetingRegionInfo,
      meetingSchedule,
      desiredDeadlineAt,
      description,
    } = values;

    const region1DepthCodeItemName = preferredMeetingRegionInfo?.[0];
    const region2DepthCodeItemName = preferredMeetingRegionInfo?.[1];

    const generateProjectTaskList = [
      {
        job: user!.job!,
        isProjectRegistrantTask: true,
        recruitsNumber: myTaskRecruitsNumber,
        jobDescription: myTaskJobDescription,
      },
      ...(projectTaskList || []).map((el) => {
        return { ...el, isProjectRegistrantTask: false };
      }),
    ];

    mutate({
      projectName,
      projectPlatformList,
      meetingChannel,
      region1DepthCodeItemName,
      region2DepthCodeItemName,
      meetingSchedule,
      desiredDeadlineAt,
      description,
      generateProjectTaskList,
    });
  };

  const onFinish = (values: IFormInput) => {
    setIsRegisterModalOpen(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    const namePath = errorInfo.errorFields[0].name;
    form.getFieldInstance(namePath)?.focus();
  };

  return (
    <Container>
      <Title>프로젝트 등록</Title>

      <Form
        form={form}
        layout="vertical"
        variant="outlined"
        style={{
          width: isMobile ? "90%" : "100%",
          padding: isMobile ? "30px 0px" : "30px 60px",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<StyledLabel>프로젝트명</StyledLabel>}
          name={["projectName"]}
          rules={[{ required: true, message: "프로젝트명을 작성해주세요." }]}
        >
          <StyledInput
            placeholder="프로젝트명을 작성해주세요."
            autoComplete="off"
            onPressEnter={handlePressEnter}
          />
        </Form.Item>

        <Form.Item
          label={<StyledLabel>플랫폼</StyledLabel>}
          name={["projectPlatformList"]}
          rules={[{ required: true, message: "플랫폼을 선택해주세요." }]}
        >
          <StyledSelect
            mode="multiple"
            allowClear
            optionFilterProp="label"
            placeholder="플랫폼을 선택해주세요."
            options={platformOptions}
          />
        </Form.Item>

        <Form.Item label="직무" required>
          {/* 내 직무 */}
          {isProfileComplete && (
            <ProjectTaskWrapper>
              <MyTaskJob>{jobViewer(user!.job!)} (나)</MyTaskJob>
              <Form.Item
                name={["myTaskRecruitsNumber"]}
                initialValue={1}
                rules={[
                  { required: true, message: "모집 인원 수를 선택하세요." },
                ]}
              >
                <Select
                  options={Array.from({ length: 10 }, (_, i) => ({
                    label: `${i + 1}명`,
                    value: i + 1,
                  }))}
                  placeholder="모집 인원 수"
                  style={{ width: 80, height: 32 }}
                />
              </Form.Item>
              <Form.Item
                name={["myTaskJobDescription"]}
                rules={[{ max: 30, message: "30자 이내로 입력해 주세요." }]}
                style={{ flex: 1 }}
              >
                <Input
                  placeholder="필요 스킬 또는 역량에 대해 작성해주세요. (30자 이내)"
                  style={{ height: 32 }}
                  autoComplete="off"
                />
              </Form.Item>
              <CloseOutlined
                disabled
                style={{ height: 32, color: "#ffffff" }}
              />
            </ProjectTaskWrapper>
          )}
          {/* 추가 직무 */}
          <Form.List
            name={["projectTaskList"]}
            // rules={[{validator: async (_, value) => {if (!value || value.length === 0) {return Promise.reject(new Error("최소 1개의 직무를 추가해야 합니다."));}return Promise.resolve();},},]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <ProjectTaskWrapper key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, "job"]}
                      rules={[
                        { required: true, message: "직무를 선택하세요." },
                      ]}
                    >
                      <Select
                        options={jobOptions}
                        placeholder="직무"
                        style={{ width: 120, height: 32 }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "recruitsNumber"]}
                      initialValue={1}
                      rules={[
                        {
                          required: true,
                          message: "모집 인원 수를 선택하세요.",
                        },
                      ]}
                    >
                      <Select
                        options={Array.from({ length: 10 }, (_, i) => ({
                          label: `${i + 1}명`,
                          value: i + 1,
                        }))}
                        placeholder="모집 인원 수"
                        style={{ width: 80, height: 32 }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "jobDescription"]}
                      rules={[
                        { max: 30, message: "30자 이내로 입력해 주세요." },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input
                        placeholder="필요 스킬 또는 역량에 대해 작성해주세요. (30자 이내)"
                        style={{ height: 32 }}
                        autoComplete="off"
                      />
                    </Form.Item>
                    <CloseOutlined
                      onClick={() => remove(name)}
                      style={{ height: 32 }}
                    />
                  </ProjectTaskWrapper>
                ))}
                {fields.length < 9 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      block
                    >
                      직무 추가
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          label={<StyledLabel>회의 장소</StyledLabel>}
          name={["meetingChannel"]}
          rules={[{ required: true, message: "회의 장소를 선택해주세요." }]}
        >
          <StyledSelect
            showSearch
            optionFilterProp="label"
            placeholder="회의 장소를 선택해주세요."
            options={meetingChannelOptions}
            onChange={handleMeetingChannelChange}
          />
        </Form.Item>

        {isOfflineIncluded ? (
          <Form.Item
            label={<StyledLabel>회의 선호 지역</StyledLabel>}
            name={["preferredMeetingRegionInfo"]}
            rules={[
              { required: true, message: "회의 선호 지역을 선택해주세요." },
            ]}
          >
            <StyledCascader
              options={koreaRegionCascaderOptions}
              dropdownMenuColumnStyle={{ width: "150px" }}
            />
          </Form.Item>
        ) : null}

        <Form.Item
          label={<StyledLabel>회의 일정</StyledLabel>}
          name={["meetingSchedule"]}
        >
          <StyledInput
            placeholder="예시) 주 1회"
            autoComplete="off"
            onPressEnter={handlePressEnter}
          />
        </Form.Item>

        <Form.Item
          label={<StyledLabel>목표 마감 기한</StyledLabel>}
          name={["desiredDeadlineAt"]}
          rules={[
            { required: true, message: "목표 마감 기한을 선택해주세요." },
          ]}
        >
          <StyledDatePicker placeholder="목표 마감 기한을 선택해주세요." />
        </Form.Item>

        <Form.Item
          label={<StyledLabel>프로젝트 설명</StyledLabel>}
          name={["description"]}
          rules={[{ required: true, message: "프로젝트 설명을 작성해주세요." }]}
        >
          <StyledTextArea
            placeholder="어떤 프로젝트인지 간단하게 설명해주세요."
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </Form.Item>

        <Form.Item>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              paddingTop: "30px",
            }}
            size="middle"
          >
            <Button
              type="default"
              htmlType="button"
              onClick={() => setIsHandleBackModalOpen(true)}
              style={{ height: "44px", width: "110px" }}
            >
              이전
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: "44px", width: "110px" }}
            >
              등록
            </Button>
            <Modal
              open={isRegisterModalOpen}
              onOk={handleRegister}
              okText="네"
              onCancel={() => setIsRegisterModalOpen(false)}
              cancelText="취소"
              width={400}
              centered={isMobile ? true : false}
            >
              <ModalContent>프로젝트를 등록하시겠습니까?</ModalContent>
            </Modal>
            <Modal
              open={!isProfileComplete}
              onOk={() => navigate(pageRoute.MYPAGE)}
              okText="네"
              onCancel={() => navigate(-1)}
              cancelText="취소"
              closable={false}
              maskClosable={false}
              width={400}
              centered={isMobile ? true : false}
            >
              <ModalContent>
                내 정보를 입력해야 프로젝트를 등록할 수 있어요.
                <br />
                지금 내 정보를 입력하시겠어요?
              </ModalContent>
            </Modal>
          </Space>
        </Form.Item>
      </Form>
    </Container>
  );
}

export default ProjectRegister;
