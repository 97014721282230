import useWindowSize from "../../../hooks/useWindowSize";
import HeaderDesktop from "../Header/HeaderDesktop";
import SidebarDesktop from "../Sidebar/SidebarDesktop";
import HeaderMobile from "../Header/HeaderMobile";

function Navigation() {
  const { isMobile } = useWindowSize();

  return isMobile ? (
    <>
      <HeaderMobile />
    </>
  ) : (
    <>
      <HeaderDesktop />
      <SidebarDesktop />
    </>
  );
}

export default Navigation;
