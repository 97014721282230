import styled from "styled-components";
import { Link } from "react-router-dom";
import { pageRoute } from "../../../constants/pageRoute.constant";
import { localStorageKey } from "../../../constants/localStorageKey.constant";
import { getLocalStorage } from "../../../helpers/storage.helper";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { useSignOut } from "../../../hooks/queries/useAccountQuery";
import { message } from "antd";

const Wrapper = styled.div`
  font-size: ${(props) => props.theme.textSize.normal};
`;

const Header = styled.nav`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  height: 60px;
  width: 100%;
  z-index: 1000;
  padding: 20px 30px;
  color: ${(props) => props.theme.textColor.black};
  font-weight: ${(props) => props.theme.fontWeight.light};
  background-color: ${(props) => props.theme.backgroundColor.white};
  white-space: nowrap;
`;

const Logo = styled.div`
  font-size: ${(props) => props.theme.textSize.large};
  font-weight: ${(props) => props.theme.fontWeight.extraBold};
`;

const Hamburger = styled(MenuOutlined)`
  margin-left: auto;
`;

const Close = styled(CloseOutlined)`
  margin-left: auto;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  min-height: calc(100vh - 60px);
  width: 100%;
  z-index: 800;
  padding: 20px 30px;
  color: ${({ theme }) => theme.textColor.darkerGray};
  background-color: ${(props) => props.theme.backgroundColor.white};
`;

const MenuDimmer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  min-height: calc(100vh - 60px);
  width: 100%;
  z-index: 1000;
  padding: 20px 30px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColor.gray};
  margin: 16px 0px;
`;

const MenuLink = styled(Link)`
  padding: 16px 0px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const MenuButton = styled.div`
  padding: 16px 0px;
  color: ${({ theme }) => theme.textColor.lightestGray};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubMenuTitle = styled.div`
  padding: 16px 0px;
  color: ${({ theme }) => theme.textColor.lightestGray};
`;

const SubMenuLink = styled(Link)`
  padding: 16px 20px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function HeaderMobile() {
  const isAuthenticated = !!getLocalStorage(localStorageKey.BEARER_TOKEN);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { mutateAsync: mutateAsyncSignOut, isLoading: isSigningOut } =
    useSignOut();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  const switchIsMenuOpen = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      await mutateAsyncSignOut();
      switchIsMenuOpen();
    } catch (error) {
      message.error("로그아웃에 실패하였습니다.");
    }
  }, [mutateAsyncSignOut, switchIsMenuOpen]);

  return (
    <Wrapper>
      <Header>
        <Logo>
          <Link
            to={pageRoute.HOME}
            onClick={() => {
              if (isMenuOpen) switchIsMenuOpen();
            }}
          >
            SubTask
          </Link>
        </Logo>

        {!isMenuOpen ? (
          <Hamburger onClick={switchIsMenuOpen} />
        ) : (
          <Close onClick={switchIsMenuOpen} />
        )}
      </Header>

      {isSigningOut && <MenuDimmer />}

      {isMenuOpen && (
        <Menu>
          <MenuLink to={pageRoute.PROJECT_REGISTER} onClick={switchIsMenuOpen}>
            프로젝트 등록
          </MenuLink>

          <MenuLink to={pageRoute.PROJECT_LIST} onClick={switchIsMenuOpen}>
            프로젝트 찾기
          </MenuLink>

          <Divider />

          {isAuthenticated ? (
            <>
              <SubMenu>
                <SubMenuTitle>내 프로젝트 관리</SubMenuTitle>

                <SubMenuLink
                  to={pageRoute.MY_PROJECT_RECRUITING_PROJECT_LIST}
                  onClick={switchIsMenuOpen}
                >
                  프로젝트 등록
                </SubMenuLink>
                <SubMenuLink
                  to={pageRoute.MY_PROJECT_APPLIED_PROJECT_LIST}
                  onClick={switchIsMenuOpen}
                >
                  프로젝트 지원
                </SubMenuLink>
                <SubMenuLink
                  to={pageRoute.MY_PROJECT_IN_PROGRESS_PROJECT_LIST}
                  onClick={switchIsMenuOpen}
                >
                  프로젝트 진행
                </SubMenuLink>
                <SubMenuLink
                  to={pageRoute.MY_PROJECT_COMPLETE_PROJECT_LIST}
                  onClick={switchIsMenuOpen}
                >
                  프로젝트 완료
                </SubMenuLink>
              </SubMenu>

              <MenuLink to={pageRoute.MYPAGE} onClick={switchIsMenuOpen}>
                마이페이지
              </MenuLink>

              <Divider />

              <MenuButton onClick={handleSignOut}>로그아웃</MenuButton>
            </>
          ) : (
            <MenuLink to={pageRoute.SIGN_IN} onClick={switchIsMenuOpen}>
              로그인
            </MenuLink>
          )}
        </Menu>
      )}
    </Wrapper>
  );
}

export default HeaderMobile;
