import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { localStorageKey } from "./constants/localStorageKey.constant";
import { userState } from "./atoms/accountState";
import { getLocalStorage } from "./helpers/storage.helper";
import Loading from "./components/shared/Loading";
import Modals from "./components/shared/Modals";
import ScrollToTop from "./components/shared/ScrollToTop";
import { commonConstants } from "./constants/common.constant";
import Navigation from "./components/layouts/Navigation/Navigation";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${commonConstants.BREAKPOINT_DESKTOP_MIN}px) and (max-width: ${commonConstants.DESKTOP_SIDEBAR_NO_OVERLAY}px) {
    align-items: flex-start;
    margin-left: 260px;
  }
  @media screen and (min-width: ${commonConstants.BREAKPOINT_DESKTOP_MIN}px) {
    margin-top: 110px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    margin-top: 60px;
  }
`;

function App() {
  const [user, setUser] = useRecoilState(userState);

  const isAuthenticated = !!getLocalStorage(localStorageKey.BEARER_TOKEN);
  if (!isAuthenticated && user) {
    setUser(null);

    return null;
  }

  return (
    <Router>
      <Loading />
      <Modals />
      <ScrollToTop />
      <Navigation />
      <Main>
        <AppRoutes />
      </Main>
    </Router>
  );
}

export default App;
