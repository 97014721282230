import { Button, Modal } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { isHandleBackModalOpenState } from "../../../atoms/modalState";
import { jobViewer, ProjectWorkerStatus } from "../../../types";
import {
  useAcceptApplicantByProjectWorkerId,
  useGetApplicantsByProjectId,
  useRejectApplicantByProjectWorkerId,
} from "../../../hooks/queries/useProjectQuery";
import { useCallback, useEffect, useState } from "react";
import { isGlobalLoadingState } from "../../../atoms/loadingState";
import { defaultList } from "../../../types/common.type";
import { commonConstants } from "../../../constants/common.constant";

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 58px 0px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 90%;
    padding: 40px 0px;
  }
`;

const Container = styled.div`
  width: 637px;
  padding: 33px 26px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.lightCoolGray};
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    width: 100%;
    padding: 20px;
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.textSize.medium};
  margin-bottom: 46px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    font-size: ${({ theme }) => theme.textSize.normal};
    margin-bottom: 30px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: ${(props) => props.theme.textSize.small};
  white-space: pre-line;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    gap: 15px;
  }
`;

const ListTotalCount = styled.div``;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 24px;
  background-color: ${({ theme }) => theme.backgroundColor.white};
  border-radius: 16px;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    padding: 20px;
  }
`;

const ListItemField = styled.div`
  display: flex;
  @media screen and (max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const ListItemFieldLabel = styled.div`
  width: 133px;
  flex-shrink: 0;
`;

const ListItemFieldValue = styled.div`
  color: ${({ theme }) => theme.textColor.gray};
`;

const ListItemButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-top: 15px;
  color: ${({ theme }) => theme.textColor.lighterBlue};
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
`;

interface IProps {
  projectId: string;
  isMobile: boolean;
}

function ApplicantsInfo({ projectId, isMobile }: IProps) {
  const setIsHandleBackModalOpen = useSetRecoilState(
    isHandleBackModalOpenState
  );
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [projectWorkerId, setProjectWorkerId] = useState<number | null>(null);
  const [isGlobalLoading, setIsGlobalLoading] =
    useRecoilState(isGlobalLoadingState);
  const { data: applicantList = defaultList, refetch } =
    useGetApplicantsByProjectId({
      projectId,
    });
  const { rows: applicants, count } = applicantList;
  const {
    mutate: mutateReject,
    isLoading: isRejectLoading,
    isSuccess: isRejectSuccess,
  } = useRejectApplicantByProjectWorkerId();
  const {
    mutate: mutateAccept,
    isLoading: isAcceptLoading,
    isSuccess: isAcceptSuccess,
  } = useAcceptApplicantByProjectWorkerId();

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((isRejectLoading || isAcceptLoading) && !isGlobalLoading)
      setIsGlobalLoading(true);
    if (!isRejectLoading && !isAcceptLoading && isGlobalLoading)
      setIsGlobalLoading(false);
    // eslint-disable-next-line
  }, [isRejectLoading, isAcceptLoading]);

  useEffect(() => {
    if (isRejectSuccess || isAcceptSuccess) {
      refetch();
    }
  }, [isRejectSuccess, isAcceptSuccess, refetch]);

  const handleReject = useCallback(() => {
    mutateReject({ projectWorkerId: projectWorkerId! });
    setIsRejectModalOpen(false);
  }, [mutateReject, projectWorkerId]);

  const handleAccept = useCallback(() => {
    mutateAccept({ projectWorkerId: projectWorkerId! });
    setIsAcceptModalOpen(false);
  }, [mutateAccept, projectWorkerId]);

  return (
    <Wrapper>
      <Container>
        <Title>지원자 목록</Title>

        <List>
          <ListTotalCount>총 {count}명</ListTotalCount>
          {applicants.map((applicant) => {
            const {
              id,
              projectWorkerStatus,
              email,
              job,
              skillNameList,
              yearOfJob,
              memo,
            } = applicant;

            return (
              <ListItem key={id}>
                <ListItemField>
                  <ListItemFieldLabel>이메일</ListItemFieldLabel>
                  <ListItemFieldValue>{email}</ListItemFieldValue>
                </ListItemField>
                <ListItemField>
                  <ListItemFieldLabel>직무</ListItemFieldLabel>
                  <ListItemFieldValue>{jobViewer(job)}</ListItemFieldValue>
                </ListItemField>
                <ListItemField>
                  <ListItemFieldLabel>스킬</ListItemFieldLabel>
                  <ListItemFieldValue>
                    {skillNameList.join(", ")}
                  </ListItemFieldValue>
                </ListItemField>
                <ListItemField>
                  <ListItemFieldLabel>경력</ListItemFieldLabel>
                  <ListItemFieldValue>{yearOfJob + "년"}</ListItemFieldValue>
                </ListItemField>
                <ListItemField>
                  <ListItemFieldLabel>남긴 메모</ListItemFieldLabel>
                  <ListItemFieldValue>{memo || "-"}</ListItemFieldValue>
                </ListItemField>

                {projectWorkerStatus === ProjectWorkerStatus.APPLIED && (
                  <ListItemButtonWrapper>
                    <Button
                      color="danger"
                      variant="outlined"
                      htmlType="button"
                      onClick={() => {
                        setProjectWorkerId(id);
                        setIsRejectModalOpen(true);
                      }}
                      style={{ height: "30px", width: "80px" }}
                    >
                      거절
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      htmlType="button"
                      onClick={() => {
                        setProjectWorkerId(id);
                        setIsAcceptModalOpen(true);
                      }}
                      style={{ height: "30px", width: "80px" }}
                    >
                      승낙
                    </Button>
                  </ListItemButtonWrapper>
                )}

                {projectWorkerStatus === ProjectWorkerStatus.ACCEPTED && (
                  <ListItemButtonWrapper>승낙했어요!</ListItemButtonWrapper>
                )}
              </ListItem>
            );
          })}
        </List>
      </Container>

      <Modal
        open={isRejectModalOpen}
        onOk={handleReject}
        okText="네"
        onCancel={() => setIsRejectModalOpen(false)}
        cancelText="취소"
        width={400}
        centered={isMobile ? true : false}
      >
        <ModalContent>
          '거절'하실 경우 다시 수정할 수 없어요.
          <br />
          그대로 진행하시겠어요?
        </ModalContent>
      </Modal>

      <Modal
        open={isAcceptModalOpen}
        onOk={handleAccept}
        okText="네"
        onCancel={() => setIsAcceptModalOpen(false)}
        cancelText="취소"
        width={400}
        centered={isMobile ? true : false}
      >
        <ModalContent>
          '승낙'하실 경우 다시 수정할 수 없어요.
          <br />
          그대로 진행하시겠어요?
        </ModalContent>
      </Modal>

      <ButtonWrapper>
        <Button
          type="default"
          htmlType="button"
          onClick={() => setIsHandleBackModalOpen(true)}
          style={{ height: "44px", width: "110px" }}
        >
          이전
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default ApplicantsInfo;
