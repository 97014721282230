import { useState, useEffect } from "react";
import { commonConstants } from "../constants/common.constant";

function useMediaQuery(query: string, defaultState = false): boolean {
  const [matches, setMatches] = useState(defaultState);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    setMatches(mediaQuery.matches); // 초기 값 설정

    const handleChange = () => setMatches(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleChange);

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, [query]);

  return matches;
}

function useWindowSize(): { isMobile: boolean } {
  const isMobile = useMediaQuery(
    `(max-width: ${commonConstants.BREAKPOINT_MOBILE_MAX}px)`,
    window.innerWidth <= commonConstants.BREAKPOINT_MOBILE_MAX
  );
  // const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");
  // const isDesktop = useMediaQuery("(min-width: 1025px)");

  return { isMobile };
}

export default useWindowSize;
